<template>
  <router-link :to="{ name: 'Question', params: { id: 1 } }" class="bold">
    <img :src="buttonSrc" class="play-button" />  
  </router-link
  >
</template>

<script>
import { getSystemInfos } from '../services/jsonService';

export default {
  name: "PlayButton",
  data() {
    return {
      buttonSrc: "",
    }
  },
  mounted() { 
    this.buttonSrc = require('@/assets/img/' + getSystemInfos()['play-button-img']);
  }
};
</script>

<style lang="scss" scoped>
.play-button {
  width: 40vw;
}
</style>
