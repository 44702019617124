<template>
  <div class="body">
    <div class="ct">
      <a @click="security">
        <img :src="headerLogo" class="img-logo" />
      </a>
      <img :src="secondaryHeaderImgSrc" class="img-display" />
      <router-view
        :minutes="minutes"
        :seconds="seconds"
        :playerScore="playerScore"
        @startTimer="startTimerHandler"
        @stopTimer="stopTimerHandler"
        @resetTimer="resetTimerHandler"
        @addScore="addScoreHandler"
        @resetScore="resetScoreHandler"
      ></router-view>
    </div>
    <div class="ct-mint">
      <a href="https://agence-mint.ch" target="_BLANK"><img class="logo-mint" src="./assets/img/signature_mint.svg" alt="Signature Agence MiNT" /></a>
      <a href="https://www.custom-design.ch/fr" target="_BLANK"><img src="./assets/img/custom_design_logo.png" alt="Logo Custom Design" class="logo-design"></a>
    </div>
  </div>
</template>

<script>
import { getSystemInfos } from '@/services/jsonService';

export default {
  name: "App",
  data() {
    return {
      minutes: 0,
      seconds: 0,
      intervalManager: null,
      playerScore: 0,
      headerSrc: '',
      headerLogo: '',
      headerDisplay: '',
      footerSrc: '',
      secondaryHeaderImgSrc: '',
      backgroundSrc: ''
    };
  },
  methods: {
    startTimerHandler() {
      this.intervalManager = setInterval(() => {
        if (this.seconds === 59) {
          this.minutes += 1;
          this.seconds = 0;

          if (this.minutes === 60) {
            this.gameTimeoutHandler();
          }
        } else {
          this.seconds += 1;
        }
      }, 1000);
    },

    stopTimerHandler() {
      clearInterval(this.intervalManager);
    },

    resetTimerHandler() {
      this.minutes = 0;
      this.seconds = 0;
    },

    addScoreHandler() {
      this.playerScore += 1;
    },

    resetScoreHandler() {
      this.playerScore = 0;
    },

    gameTimeoutHandler() {
      this.stopTimerHandler();
      this.resetTimerHandler();
      this.$router.push({ name: 'home' });
    },

    security() {
      alert('Gakomo');
    }
  },
  mounted() {
    this.headerSrc = getSystemInfos()['header-src'];
    this.headerLogo = require('@/assets/img/' + getSystemInfos()['header-logo']);
    this.secondaryHeaderImgSrc = require('@/assets/img/' + getSystemInfos()['header-display']);
    this.headerDisplay = getSystemInfos()['header-display'];
    this.footerSrc = getSystemInfos()['footer-src'];
    this.backgroundSrc = require('@/assets/img/' + getSystemInfos()['background']);

    // Get .body div
    const body = document.querySelector('.body');
    body.style.backgroundImage = `url(${this.backgroundSrc})`;
    body.style.backgroundSize = 'cover';
    body.style.backgroundPosition = 'center';
  }
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  font-family: $font-primary;
  color: #fff;

  /* Texte insélectionnable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  font-size: 2vw;
}

.body {
  height: 100vh;
}

.ct {
  height: calc(100vh - 7%);
  position: relative;

  .img-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .img-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .img-logo {
    width: 30%;
    position: absolute;
    top: 3%;
    margin-left: 5%;
  }

  .img-display {
    position: absolute;
    right: 5%;
    top: 3%;
    width: 50%;
  }
}

.ct-mint {
  height: calc(100vh - 93%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0 40px;

  a {
    img.logo-mint {
      width: 70%;
    } 
    img.logo-design {
      width: 25%;
      mix-blend-mode: exclusion;
      float: right;
    }
  }
}

.text-primary {
  color: $color-primary;
  font-weight: bold;
}

.font-secondary {
  font-family: $font-secondary;
}


</style>
