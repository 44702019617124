<template>
  <div class="content">
    <div class="home">
    <div class="home-title__container">
      <h1 class="home-title" :innerHTML="homeTitle.replace(/\|/g, '<br>')"></h1>
    </div>
    <div class="carousel-container">
      <!-- <HomeCarousel class="carousel" /> -->
      <img class="center-img" :src="centerImage" alt="Image chips">
      <PlayButton class="play-button" @click="$emit('startTimer'); registerDevice();" />
    </div>    
    <div class="sub-text">
      <!-- Insert secondary text here -->
    </div>
  </div>
  </div>
</template>

<script>
import PlayButton from "@/components/PlayButton";
import {getSystemInfos} from "@/services/jsonService";
// import VueCookies from 'vue-cookies';

export default {
  name: "HomeView",
  components: { PlayButton },
  data() {
    return {
      homeTitle: '',
      centerImage: '',
    }
  },
  mounted() {
    this.homeTitle = getSystemInfos()['home-title'];
    this.centerImage = require('@/assets/img/' + getSystemInfos()['home-center-image']);
  },
  // methods: {
  //   async registerDevice() {
  //     if (VueCookies.get('hasPlayed')) {
  //       this.$router.push({ name: 'AlreadyPlayed' });
  //     } else {
  //       VueCookies.set('hasPlayed', 'true', '1d');
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.home {
  margin-top: 2rem;
  margin-bottom: -40%;
  padding: 0 20px;
}
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-title__container {
  width: 100%;
  text-align: center;
  padding: 3em 0;
}

.home-title {
  width: 100%;
  font-size: 2em;
  font-weight: 300;
  line-height: 1.6em;
}

.content {
  padding-top: 30%;
  min-height: 30vh;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.sub-text {
  text-align: center;
  font-size: 2.6em;
  margin-top: 5em;
  font-weight: 700;
}

.center-img {
  width: 300px;
  height: 300px;
}
</style>
