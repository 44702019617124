import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/question/:id",
    name: "Question",
    component: () => import("@/views/QuestionView"),
  },
  {
    path: "/result/:id/:ansSelected",
    name: "Result",
    component: () => import("@/views/ResultView"),
  },
  {
    path: "/end",
    name: "End",
    component: () => import("@/views/EndView"),
  },
  {
    path: '/template',
    name: 'Template',
    component: () => import("@/views/TemplateView"),
  },
  {
    path: '/quiz-win',
    name: 'QuizWin',
    component: () => import("@/views/QuizWinView"),
  },
  {
    path: '/draw-win',
    name: 'DrawWin',
    component: () => import("@/views/DrawWinView"),
  },
  {
    path: '/draw-lose',
    name: 'DrawLose',
    component: () => import("@/views/DrawLoseView"),
  },
  {
    path: '/winner-screen',
    name: 'WinnerScreen',
    component: () => import("@/views/WinnerScreenView"),
  },
  {
    path: '/already-played',
    name: 'AlreadyPlayed',
    component: () => import("@/views/AlreadyPlayedView"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
